import _object from "../../actual/object";
import _esnextObject from "../../modules/esnext.object.has-own";
import _esnextObject2 from "../../modules/esnext.object.iterate-entries";
import _esnextObject3 from "../../modules/esnext.object.iterate-keys";
import _esnextObject4 from "../../modules/esnext.object.iterate-values";
var exports = {};
var parent = _object;
// TODO: Remove from `core-js@4`
_esnextObject;
_esnextObject2;
_esnextObject3;
_esnextObject4;
exports = parent;
export default exports;